.optimise-bids-table-wrapper .optimise-bids-main-table .optimise-bids-main-table-wrapper .optimise-bids-table {
    border-collapse: initial;
  }

.optimise-bids-main-table-wrapper {
    overflow-y: scroll;
    /* height: 88vh; */
    height: 80vh;
   
}

.optimise-bids-table-wrapper .optimise-bids-main-table .optimise-bids-main-table-wrapper .optimise-bids-table .targeting-table-heads {
    padding: 8px 20px;
    z-index: 30;
    opacity: 100%;
    border-right: 1px solid white;       
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 20;
}

.optimise-bids-table-wrapper .optimise-bids-main-table .optimise-bids-main-table-wrapper .optimise-bids-table tr td {
    font-size: 12px ! important;
    border: 1px solid #999 ! important;
    white-space: nowrap;
    text-align: left ! important
}

.optimise-bids-table th {
    white-space: nowrap;
    padding: 8px 64px 8px 8px ! important;
    text-align: left ! important;
    font-size: 14px ! important;
}

.optimise-bids-table td {
    padding: 8px 8px 8px 8px ! important;
}

.optimise-bids-table-wrapper .optimise-bids-main-table .optimise-bids-main-table-wrapper .optimise-bids-table th:nth-child(1) {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 45;
    opacity: 100%;
    border-right: 1px solid #FFFFFF;
}

.optimise-bids-table-wrapper .optimise-bids-main-table .optimise-bids-main-table-wrapper .optimise-bids-table th:nth-child(2) {
    position: sticky;
    left: 291px;
    top: 0;
    z-index: 45;
    opacity: 100%;
    border-right: 1px solid #e47518;
    box-shadow: 2px 1px 6px #c9705a91;
}

.optimise-bids-table-wrapper .optimise-bids-main-table .adjust-bid-table-wrapper .optimise-bids-table th:nth-child(2) {
    left: 318px ! important;
}
.optimise-bids-table-wrapper .optimise-bids-main-table .adjust-bid-table-wrapper .optimise-bids-table td:nth-child(2)  {
    left: 318px ! important;
}

.optimise-bids-table-wrapper .optimise-bids-main-table .optimise-bids-main-table-wrapper .optimise-bids-table td:nth-child(1) {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 40;
    opacity: 100%;
    border-right: 1px solid #FFFFFF;
    background-color: #fff;
}

.optimise-bids-table-wrapper .optimise-bids-main-table .optimise-bids-main-table-wrapper .optimise-bids-table td:nth-child(2)  {
    position: sticky;
    left: 291px;
    top: 0;
    z-index: 40;
    opacity: 100%;
    border-right: 0.5px solid #cccccc;
    box-shadow: 2px 1px 6px #cccc;
    background-color: #fff;
}

.optimise-bids-table-wrapper .optimise-bids-main-table .optimise-bids-main-table-wrapper .optimise-bids-table .figure-comparison{
    font-size: 8px;
}

#bulk-action-box-bid-optimise{
    top: 325px;
}

#bulk-action-box-bid-optimise-placement{
    top: 250px;
}



.clickable-text-color {
    color: #005AA4 !important;
}
